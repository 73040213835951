import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { useRecoilValue, useRecoilState } from 'recoil'
import axios from 'axios'

import Spinner from 'src/components/shared/Spinner'
import { colors } from 'src/styles/variables'

import { productDetailState } from 'src/actions/products'
import { breeds } from 'src/store/consultationAtom'
import {
  recommendationToProductSlug,
  recommendationToProductName,
  recommendationToProductDetail
} from 'src/utils/recommendations-to-product'

import DashboardNavBar from './DashboardNavBar'
import Header from './Header'
import PlanSummary from './PlanSummary'
import CardGroup from './CardGroup'
import CtaGroup from './CtaGroup'
import { shopifyCartState } from 'src/store/cartAtom'
import { smartBulkAddVariantsToCart } from 'src/actions/cart'

const SpinnerContainer = styled.div`
  height: 100vh;
  background-color: white;
`

const Container = styled.div`
  background-color: ${colors.lightText};
  min-height: 100vh;
`

const Main = styled.main`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const query = graphql`
  query {
    calming_aid: file(relativePath: { regex: "/tins/calming-aid.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    multivitamin: file(relativePath: { regex: "/tins/multivitamin.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    hip_joint: file(relativePath: { regex: "/tins/hip-and-joint.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    skin_coat: file(relativePath: { regex: "/tins/skin-and-coat.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    allergy_itch: file(relativePath: { regex: "/tins/allergy-and-itch.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    digestive_probiotics: file(relativePath: { regex: "/tins/probiotics.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    ingredients: ingredientsJson {
      calming_aid {
        img {
          childImageSharp {
            gatsbyImageData(width: 250)
          }
        }
        description
        name
      }
      hip_joint {
        img {
          childImageSharp {
            gatsbyImageData(width: 250)
          }
        }
        name
        description
      }
      multivitamin {
        description
        img {
          childImageSharp {
            gatsbyImageData(width: 250)
          }
        }
        name
      }
      skin_coat {
        description
        img {
          childImageSharp {
            gatsbyImageData(width: 250)
          }
        }
        name
      }
      digestive_probiotics {
        description
        img {
          childImageSharp {
            gatsbyImageData(width: 250)
          }
        }
        name
      }
      allergy_itch {
        description
        img {
          childImageSharp {
            gatsbyImageData(width: 250)
          }
        }
        name
      }
    }
  }
`

const determineInterval = (weight: number) => {
  if (weight > 75) return 21
  if (weight > 50) return 30
  if (weight > 25) return 45
  return 90
}

const determineChewCount = (weight: number) => {
  if (weight > 75) return 4
  if (weight > 50) return 3
  if (weight > 25) return 2
  return 1
}

const determineBreedName = ({ breed, breedPartOne }: { breed: string; breedPartOne: string }) => {
  // If user indicated mixed breed in 7A, even if they specify closest breed in 7B, still return "mixed"
  if (breedPartOne === 'mixed-breed') return 'Mixed'

  // Search for breed name, else return "unknown"
  const foundBreed = breeds.find(({ value }: { value: string }) => value === breed)
  return foundBreed ? foundBreed.text : 'Unknown'
}

const prettyAgeText = (age: number) => {
  if (age >= 24) return `${Math.floor(age / 12)} years`
  if (age >= 12) return `1 year`
  return `${age} months`
}

const DashboardWrapper = ({ id }: { id: any }) => {
  const data = useStaticQuery(query)
  const [dashboardData, setDashboardData] = useState(null)
  const [loading, setLoading] = useState(true)
  const products = useRecoilValue(productDetailState)
  const [cartState, setCartState] = useRecoilState(shopifyCartState)

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const { data: consultationData } = await axios.get(`/api/consultation/${id}`)
        setDashboardData(consultationData)
      } catch (err) {
        console.log('Consultation Error', err)
      } finally {
        setLoading(false)
      }
    }

    if (id) fetchData()
    else navigate('/consultation')
  }, [id])

  useEffect(() => {
    // wait for products and checkout id to load first
    if (!products.fetching && !cartState.fetching && !loading && dashboardData) {
      const { weight } = dashboardData.attributes
      const items = dashboardData.recommendations.map(({ name }: { name: string }) => {
        const productSlug = recommendationToProductSlug[name]
        const productName = recommendationToProductName[name]

        const { variantId, pricing } = products.bySlug[productSlug].single

        const hasSubscription = pricing.subscription

        const { price } = hasSubscription ? pricing.subscription : pricing.oneTime

        return {
          productName,
          variantId: variantId.encoded,
          price,
          quantity: 1,
          frequency: hasSubscription ? determineInterval(weight) : 0,
          subscription: hasSubscription ? pricing.subscription : null
        }
      })

      smartBulkAddVariantsToCart({
        setCartState,
        cart: cartState.cart,
        items
      })
    }
  }, [dashboardData, products.fetching, cartState.fetching, loading])

  if (loading)
    return (
      <SpinnerContainer>
        <Spinner color={colors.navy} />
      </SpinnerContainer>
    )

  const initialDashboardData = {
    name: '',
    recommendations: [],
    attributes: { breed: '', breedPartOne: '', age: 0, weight: 0, gender: '' },
    User: { first_name: '' }
  }

  const { name, recommendations, attributes, User } = dashboardData || initialDashboardData

  const { breed, breedPartOne, age, weight, gender } = attributes
  const { first_name } = User
  const supplementText = recommendations.length === 2 ? 'two supplements' : 'supplement'
  const breedName = determineBreedName({ breed, breedPartOne })
  const ageText = prettyAgeText(age)
  const weightText = `${weight} lbs`
  const recommendationsText = recommendations
    .map(({ name }: { name: string }) => recommendationToProductName[name])
    .join(', ')
  const recommendedIntervalText = `Every ${determineInterval(weight)} days`
  const hisOrHer = gender === 'male' ? 'his' : 'her'
  const ctaGroupBodyText = `We factored in characteristics like ${name}’s breed, age, weight, and more to recommend which of our premium supplements will promote good health and help keep ${hisOrHer} tail wagging! Because when your pet is healthy, everybody is happy.`

  const planSummaryInfo: {
    [key: string]: string
  } = {
    firstName: first_name,
    petName: name,
    supplementText,
    breedName,
    ageText,
    weightText,
    recommendationsText,
    recommendedIntervalText
  }

  let checkoutUrl = ''
  if (cartState.cart) {
    checkoutUrl = cartState.cart.checkoutUrl
  }

  return (
    <Container>
      <DashboardNavBar checkoutUrl={checkoutUrl} />
      <Main>
        <Header petName={name} />
        <PlanSummary planSummaryInfo={planSummaryInfo} />
        {recommendations.map((recommendation: any, idx: number) => (
          <CardGroup
            key={recommendation.name}
            petName={name}
            recommendation={recommendation}
            idx={idx}
            product={recommendationToProductDetail[recommendation.name]}
            weightText={weightText}
            count={determineChewCount(weight)}
            checkoutUrl={checkoutUrl}
            data={data}
          />
        ))}
        <CtaGroup
          headline={`Trusted Nutrition for ${name}`}
          bodyText={ctaGroupBodyText}
          checkoutUrl={checkoutUrl}
        />
        {/* <pre>{JSON.stringify(dashboardData, null, 2)}</pre> */}
      </Main>
    </Container>
  )
}

export default memo(DashboardWrapper)
